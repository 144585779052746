import {
  Alert,
  Box,
  Button,
  LinearProgress,
  Paper,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { persistAuth, useAuth } from '../../../../Tech/Auth/provider';
import DefaultLayout from '../../../../Ui/Layouts/defaultLayout';
import { register as registerApi } from '../../services/api';
import defaultPaper from '../../../../Ui/Layouts/defaultPaper';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingState } from '../../../../Tech/Types/loadingStates';
import { RegisterValues } from '../../types/register';
import ReactFlagsSelect from 'react-flags-select';

const oldShemaLogin = yup
  .object({
    firstname: yup.string().required('firstname is required'),
    lastname: yup.string().required('Username is required'),
    username: yup
      .string()
      .required('Username is required')
      .matches(/^[a-zA-Z0-9_]+$/, "can only contain letters, digits and '_'"),
    email: yup
      .string()
      .email('enter a valid email address')
      .required('Email is required'),
    password: yup
      .string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
      ),
    confirmPassword: yup
      .string()
      .required('Confirm Password is required')
      .oneOf([yup.ref('password'), null], 'Passwords do not match'),
    nationality: yup.number().required(),
  })

const shemaLogin = yup
  .object({
    firstname: yup.string().required('firstname is required'),
    lastname: yup.string().required('Username is required'),
    username: yup
      .string()
      .required('Username is required')
      .matches(/^[a-zA-Z0-9_]+$/, "can only contain letters, digits and '_'"),
    email: yup
      .string()
      .email('enter a valid email address')
      .required('Email is required'),
    password: yup
      .string()
      .required('Password is required')
      .matches(
        /^.{6,}$/,
        'Must contain at least 6 characters',
      ),
    confirmPassword: yup
      .string()
      .required('Confirm Password is required')
      .oneOf([yup.ref('password'), null], 'Passwords do not match'),
    nationality: yup.number().required(),
  })
  .required();

const Register = () => {
  const [loadingState, setLoadingState] = useState(LoadingState.TO_START);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [selected, setSelected] = useState('');

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<RegisterValues>({
    resolver: yupResolver(shemaLogin),
  });

  const navigate = useNavigate();
  const auth = useAuth();

  const onSubmit: SubmitHandler<RegisterValues> = async (data) => {
    setLoadingState(LoadingState.LOADING);
    try {
      // data.nationality = 75;
      const result = await registerApi(data);
      const authResult = persistAuth(result.token);
      auth.authContextLogin({
        nickname: authResult.nickname,
        clientId: authResult.clientId,
      });
      setLoadingState(LoadingState.SUCCESS);
      navigate('/');
    } catch {
      setErrorMessage('something went wrong');
      setLoadingState(LoadingState.ERROR);
    }
  };

  return (
    <DefaultLayout appBar leftButton={false}>
      <form className="flex column" onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            marginTop: '40px',
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Paper sx={defaultPaper}>
            <TextField
              error={!!errors.firstname}
              helperText={
                errors.firstname ? errors.firstname.message : undefined
              }
              label="firstname"
              {...register('firstname', { required: true })}
              sx={{ m: 1, width: '90%' }}
              size="small"
              variant="standard"
            />

            <TextField
              error={!!errors.lastname}
              helperText={errors.lastname ? errors.lastname.message : undefined}
              label="lastname"
              {...register('lastname', { required: true })}
              sx={{ m: 1, width: '90%' }}
              size="small"
              variant="standard"
            />

            <TextField
              error={!!errors.username}
              helperText={errors.username ? errors.username.message : undefined}
              label="Username"
              {...register('username', { required: true })}
              sx={{ m: 1, width: '90%' }}
              size="small"
              variant="standard"
            />

            <TextField
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : undefined}
              label="Email"
              {...register('email', { required: true })}
              sx={{ m: 1, width: '90%' }}
              size="small"
              variant="standard"
            />

            <TextField
              error={!!errors.password}
              helperText={errors.password ? errors.password.message : undefined}
              label="Password"
              {...register('password', { required: true })}
              sx={{ m: 1, width: '90%' }}
              type={'password'}
              size="small"
              variant="standard"
            />

            <TextField
              error={!!errors.confirmPassword}
              helperText={
                errors.confirmPassword
                  ? errors.confirmPassword.message
                  : undefined
              }
              label="Confirm Password"
              {...register('confirmPassword')}
              sx={{ m: 1, mb: 3, width: '90%' }}
              type={'password'}
              size="small"
              variant="standard"
            />

            <ReactFlagsSelect
              searchable
              selected={selected}
              onSelect={(code) => {
                setValue('nationality', 75);
                setSelected(code);
              }}
            />

            {errors.nationality && (
              <p style={{ color: 'red' }}>nationality is required</p>
            )}

            <Box marginY={2}>
              <Button variant="outlined" type="submit">
                Register
              </Button>
            </Box>

            {loadingState === LoadingState.ERROR && (
              <Box m={2}>
                <Alert className="mt-20" severity="error">
                  {errorMessage}
                </Alert>
              </Box>
            )}

            {loadingState === LoadingState.LOADING && (
              <Box marginY={2} width="90%">
                <LinearProgress />
              </Box>
            )}
          </Paper>
        </Box>
      </form>
    </DefaultLayout>
  );
};

export default Register;
