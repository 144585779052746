import React, { useState, useEffect } from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import { Routes, Route, Link, BrowserRouter } from 'react-router-dom';
import zythoTheme from './Ui/Themes/zythoTheme';
import ZythoRouter from './Business';
import {
  AuthContext,
  getAuthResult,
  getAuthTokenDataLS,
  isAuthLocalStorage,
  unpersistAuth,
} from './Tech/Auth/provider';
import { AuthTokenData } from './Business/User/types/authTokenData';
import { API } from './Tech/config/api';

function App() {
  const [authTokenData, setAuthTokenData] = useState<AuthTokenData | null>(
    getAuthTokenDataLS(),
  );

  const authContextLogin = (user: AuthTokenData) => {
    setAuthTokenData(user);
  };

  const authContextLogout = () => {
    unpersistAuth();
    setAuthTokenData(null);
  };

  useEffect(() => {
    isAuthLocalStorage();
    const authResult = getAuthResult();
    setAuthTokenData(
      authResult
        ? { clientId: authResult.clientId, nickname: authResult.nickname }
        : null,
    );
  }, []);

  return (
    <AuthContext.Provider
      value={{ authTokenData, authContextLogin, authContextLogout }}
    >
      <BrowserRouter>
        <div className="App">
          <ThemeProvider theme={zythoTheme}>
            <Routes>
              <Route path="*" element={<ZythoRouter />} />
            </Routes>
          </ThemeProvider>
        </div>
      </BrowserRouter>
    </AuthContext.Provider>
  );
}

export default App;
