import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import { Box } from '@mui/material';
import { User } from '../types/user';
import './ProfileHeader.css';

interface props {
  user: User;
}

const ProfileHeader = ({ user }: props) => {
  return user ? (
    <Box id="profile-header">
      <AccountCircleTwoToneIcon className="avatar"></AccountCircleTwoToneIcon>
      <h1 className="username">{user.username}</h1>
      <Box id="user-data">
        <h2 id="user-data-beers">
          {user.beers} beer{user.beers > 1 && 's'}
        </h2>
        <h2 id="user-data-ratings">
          {user.ratings} review{user.ratings > 1 && 's'}
        </h2>
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default ProfileHeader;
