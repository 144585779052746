import { Box, TablePagination } from '@mui/material';
import React from 'react';
import { Review } from '../../Beer/Types/review';
import { getUserReviews } from '../services/api';
import { User } from '../types/user';
import FeedReview from './FeedReview';
import './ProfileContent.css';
import './Feed.css';
import { useAuth } from '../../../Tech/Auth/provider';

interface props {
  user: User;
}

const Feed = ({ user }: props) => {
  const auth = useAuth();

  const [page, setPage] = React.useState(0);

  const [reviews, setReviews] = React.useState<Review[]>();
  React.useEffect(() => {
    try {
      getUserReviews(user.username, 10, page + 1).then((data) =>
        setReviews(data),
      );
    } catch {}
  }, [page]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  return reviews ? (
    <Box id="feed" className="content-element">
      <Box className="content-title">
        <h1>
          {(auth.authTokenData &&
            auth.authTokenData.nickname == user.username &&
            'Your Feed') ||
            `${user.username}'${
              user.username.endsWith('s') ? '' : 's'
            } History`}
        </h1>
      </Box>
      <Box id="feed-reviews">
        {reviews.map((review) => (
          <FeedReview review={review} key={review.id} />
        ))}
      </Box>
      <TablePagination
        id="pagination"
        component="div"
        count={user.ratings}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[]}
        rowsPerPage={10}
        showFirstButton
        showLastButton
      />
    </Box>
  ) : (
    <Box></Box>
  );
};

export default Feed;
