import { Box } from '@mui/material';
import { useAuth } from '../../../Tech/Auth/provider';
import { Time } from '../../../Tech/config/time';
import { Review } from '../../Beer/Types/review';
import './FeedReview.css';

interface props {
  review: Review;
}

const FeedReview = ({ review }: props) => {
  const auth = useAuth();

  return (
    <Box className="feed-review">
      <Box className="review-info">
        <h1 className="review-author">
          {(auth.authTokenData &&
            auth.authTokenData.nickname == review.user.username &&
            'You') ||
            review.user.username}
        </h1>
        <p className="review-date">
          on the {Time(review.date).format('Do [of] MMMM YYYY')}
        </p>
      </Box>

      <Box className="review-data">
        <h1 className="review-score">{review.score}</h1>
        <a href={`/beers/${review.beer.id}`} className="review-beer">
          {review.beer.name}
        </a>
      </Box>
    </Box>
  );
};

export default FeedReview;
