import { Box } from '@mui/material';
import * as React from 'react';
import { getBreweryBeers } from '../Services/api';
import { Beer } from '../../Beer/Types/beer';
import BreweryBeerComponent from './BreweryBeerComponent';

interface props {
  breweryId: number;
}

const BreweryBeers = ({ breweryId }: props) => {
  const [breweryBeers, setBreweryBeers] = React.useState<Beer[] | undefined>();

  React.useEffect(() => {
    getBreweryBeers(breweryId).then((data) => setBreweryBeers(data));
  }, [breweryId]);

  if (breweryBeers === undefined) return <></>;

  return (
    <Box>
      {breweryBeers.map((beer) => (
        <BreweryBeerComponent beer={beer} key={beer.id} />
      ))}
    </Box>
  );
};

export default BreweryBeers;
