import { Box } from '@mui/material';
import * as React from 'react';
import { getBeerReviews } from '../Services/api';
import { Review } from '../Types/review';
import ReviewComponent from './ReviewComponent';

interface props {
  beerId: number;
}

const BeerReviews = ({ beerId }: props) => {
  const [beerReviews, setBeerReviews] = React.useState<Review[] | undefined>();

  React.useEffect(() => {
    getBeerReviews(beerId).then((data) => setBeerReviews(data));
  }, [beerId]);

  if (beerReviews === undefined) return <></>;

  return (
    <Box>
      {beerReviews.map((review) => (
        <ReviewComponent review={review} key={review.id} />
      ))}
    </Box>
  );
};

export default BeerReviews;
