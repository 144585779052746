import { Box } from '@mui/material';
import React from 'react';
import { Beer } from '../../Beer/Types/beer';
import { getBeerAverageScore } from '../../Beer/Services/api';
import './BreweryBeerComponent.css';
import { AverageScore } from '../../Beer/Types/averageScore';

interface props {
  beer: Beer;
}

const BreweryBeerComponent = ({ beer }: props) => {
  const [beerAverageScore, setBeerAverageScore] =
    React.useState<AverageScore>();

  React.useEffect(() => {
    getBeerAverageScore(beer.id).then((data) => setBeerAverageScore(data));
  }, [beer]);

  // Nullable fields
  let beerColor = (
    <Box
      className="beer-color"
      style={
        beer.color
          ? { backgroundColor: '#' + beer.color.color }
          : { backgroundColor: '#f7f7f7', borderColor: '#aaa' }
      }
    >
      {beer.color ? null : '?'}
    </Box>
  );
  let beerIbu = beer.ibu ? <p className="beer-ibu">{beer.ibu}</p> : null;

  return (
    <Box className="brewery-beer">
      <h1 className="beer-name">
        <a href={'/beers/' + beer.id}>{beer.name}</a>
      </h1>

      {beerAverageScore && (
        <Box className="beer-averageScore">
          <h1 className="score">{beerAverageScore.averageScore.toFixed(1)}</h1>
          <p className="count">
            (with {beerAverageScore.reviews} review
            {beerAverageScore.reviews > 1 ? 's' : ''})
          </p>
        </Box>
      )}

      {beerColor}

      <p className="beer-style">{beer.style.name}</p>

      <Box className="beer-data">
        <p className="beer-abv">{beer.abv}</p>
        {beerIbu}
      </Box>
    </Box>
  );
};

export default BreweryBeerComponent;
