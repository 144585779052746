import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Tech/Auth/provider';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Avatar, IconButton, Link } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import AppBarMenu from './AppBarMenu';

interface appBarProps {
  leftButton: boolean;
}

const DefaultAppBar = ({ leftButton }: appBarProps) => {
  const auth = useAuth();
  const navigate = useNavigate();

  const [menuElement, setMenuElement] = React.useState<HTMLElement | null>(
    null,
  );
  const menuIsOpened = Boolean(menuElement);

  const menuClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuElement(event.currentTarget);
  };

  const menuClose = () => {
    setMenuElement(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="primary">
        <Toolbar
          sx={{
            paddingRight: '7px !important',
            justifyContent: 'space-between',
          }}
        >
          <Link
            href="/"
            style={{
              color: 'black',
              textDecoration: 'none',
            }}
          >
            <Typography variant="h6">ZYTHOGORA</Typography>
          </Link>

          <IconButton
            onClick={menuClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={menuIsOpened ? 'menu' : undefined}
            aria-haspopup="true"
            aria-expanded={menuIsOpened ? 'true' : undefined}
          >
            <Avatar sx={{ bgcolor: 'transparent' }}>
              <PersonIcon sx={{ color: 'black' }} />
            </Avatar>
          </IconButton>
          <AppBarMenu
            element={menuElement}
            close={menuClose}
            opened={menuIsOpened}
          />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default DefaultAppBar;
