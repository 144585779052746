import { Autocomplete, Box, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingState } from '../../../Tech/Types/loadingStates';
import DefaultLayout from '../../../Ui/Layouts/defaultLayout';
import { Beer } from '../../Beer/Types/beer';
import { searchBeer } from '../../Beer/Services/api';
import { Brewery } from '../../Brewery/Types/brewery';
import { searchBrewery } from '../../Brewery/Services/api';
import './index.css';

const BeerSearchResult = (props: { beer: Beer }) => {
  var result = (
    <Box className={'search-result search-result-beer'}>
      <img className={'search-result-logo'} src="img/search-bar/beer.png" />
      <Box className={'search-result-divider'}></Box>
      <Box className={'search-result-data'}>
        <p>{props.beer.name}</p>
        <Typography variant="caption">{props.beer.brewery.name}</Typography>
      </Box>
    </Box>
  );
  return result;
};

const BrewerySearchResult = (props: { brewery: Brewery }) => {
  return (
    <Box className={'search-result search-result-brewery'}>
      <img className={'search-result-logo'} src="img/search-bar/brewery.png" />
      <Box className={'search-result-divider'}></Box>
      <Box className={'search-result-data'}>
        <p>{props.brewery.name}</p>
        <Typography variant="caption">{props.brewery.country.name}</Typography>
      </Box>
    </Box>
  );
};

const Home = () => {
  const [open, setOpen] = React.useState(false);
  const [searchElements, setSearchElements] = React.useState<
    readonly (Beer | Brewery)[]
  >([]);
  const [searchLoading, setSearchLoading] = React.useState(
    LoadingState.TO_START,
  );

  const navigate = useNavigate();

  const search = (value: string) => {
    setSearchLoading(LoadingState.LOADING);

    if (searchLoading === LoadingState.LOADING) {
      return undefined;
    }

    (async () => {
      const beerResult = await searchBeer(value, 3, 1);
      const breweryResult = await searchBrewery(value, 3, 1);

      setSearchElements([...beerResult, ...breweryResult]);
    })();

    setSearchLoading(LoadingState.SUCCESS);
  };

  function handleSubmit(
    event: React.SyntheticEvent<Element, Event>,
    value: string | Beer | Brewery | null,
  ) {
    if (value === '') {
      setSearchElements([]);
    } else {
      search(value as string);
    }
  }

  function isBeer(val: Beer | Brewery): val is Beer {
    if ((val as Beer).brewery) return true;
    return false;
  }

  return (
    <DefaultLayout appBar leftButton>
      <Autocomplete
        freeSolo
        id="homeSearchBar"
        filterOptions={(x) => x}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) =>
          isBeer(option) ? `beer-${option.id}` : `brewery-${option.id}`
        }
        renderOption={(props, option: Beer | Brewery) =>
          isBeer(option) ? (
            <Box component="li" {...props}>
              <BeerSearchResult beer={option} />
            </Box>
          ) : (
            <Box component="li" {...props}>
              <BrewerySearchResult brewery={option} />
            </Box>
          )
        }
        options={searchElements}
        loading={searchLoading === LoadingState.LOADING}
        onInputChange={handleSubmit}
        onChange={(event, value) => {
          if (value === null) {
            return;
          } else if (typeof value === 'object') {
            if (isBeer(value)) navigate(`/beers/${value.id}`);
            else navigate(`/breweries/${value.id}`);
          } else if (searchElements.length > 0) {
            // ! setup search page
            navigate(`/beers/${searchElements[0].id}`);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              width: 'min(920px, 100% - 80px)',
              margin: '50px 40px auto 40px',
            }}
            variant="standard"
            label="Search a beer"
          />
        )}
      />
      <Box>
        <img
          id="banner"
          style={{
            margin: '50px 20px auto 20px',
            width: 'min(950px, 100% - 50px)',
            borderRadius: '30px',
            border: '5px solid #ffb340',
          }}
          src="img/launch-banner.png"
        />
      </Box>
      <Box
        style={{
          overflow: 'auto',
          maxWidth: 'min(960px, 100% - 40px)',
          height: '100vh',
          margin: '50px auto auto auto',
        }}
      >
        <Box
          style={{
            width: '100%',
            paddingBottom: '56.25%',
            position: 'relative',
          }}
        >
          <iframe
            style={{
              border: '0',
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
            }}
            src="https://www.youtube.com/embed/kUkJxqqqCeE"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Box>
      </Box>
    </DefaultLayout>
  );
};

export default Home;
