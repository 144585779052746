import { Alert, Box, Paper, Step, StepLabel, Stepper } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingState } from '../../../../Tech/Types/loadingStates';
import DefaultLayout from '../../../../Ui/Layouts/defaultLayout';
import defaultPaper from '../../../../Ui/Layouts/defaultPaper';
import { postBreweryBeers } from '../../../Brewery/Services/api';
import { getBeer, postBeer, postReview } from '../../Services/api';
import {
  defaultTastingInfo,
  defaultDetails,
  defaultCommentValues,
} from '../../Services/form';
import { Beer } from '../../Types/beer';
import Details from './FormPages/details';
import Comment from './FormPages/comment';
import TastinInfo from './FormPages/tastingInfo';
import { ReviewRatingComment } from '../../Types/rating';

const Rate = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [tastingInfo, setTastingInfo] = useState(defaultTastingInfo);
  const [details, setDetails] = useState(defaultDetails);
  const [isPreFilled, setIsPreFilled] = useState(false);

  const [beerLoading, setBeerLoading] = useState(LoadingState.TO_START);
  const [ratingPostLoading, setRatingPostLoading] = useState(
    LoadingState.TO_START,
  );
  const [beer, setBeer] = useState<Beer | undefined>();

  const { beerId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (beerId === undefined) {
      setBeerLoading(LoadingState.SUCCESS);
      return;
    }
    const id: number = parseInt(beerId);
    setBeerLoading(LoadingState.LOADING);
    try {
      getBeer(id).then((data) => {
        setBeer(data);
        setTastingInfo({
          ...tastingInfo,
          beerId: data.id,
          beerName: data.name,
          breweryName: data.brewery.name,
          breweryId: data.brewery.id,
          countryName: data.brewery.country.name,
          countryId: data.brewery.country.id,
          styleId: data.style.parent ? data.style.parent.id : data.style.id,
          subStyleId: data.style.parent ? data.style.id : null,
          abv: data.abv,
          ibu: data.ibu,
          color: data.color ? data.color.id : null,
        });
        setIsPreFilled(true);
        setBeerLoading(LoadingState.SUCCESS);
      });
    } catch {
      setBeerLoading(LoadingState.ERROR);
    }
  }, []);

  const resetTastingInfo = () => {
    setTastingInfo(defaultTastingInfo);
    setIsPreFilled(false);
    navigate('/rate');
  };

  //api calls

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    // let newSkipped = skipped;
    // if (isStepSkipped(activeStep)) {
    //   newSkipped = new Set(newSkipped.values());
    //   newSkipped.delete(activeStep);
    // }

    // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // setSkipped(newSkipped);
    setActiveStep(2);
  };

  const handleBack = () => {
    //setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setActiveStep(0);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handlePost = async (comment: ReviewRatingComment) => {
    setRatingPostLoading(LoadingState.LOADING);
    if (tastingInfo.breweryId === null) {
      // post a new brewery
      try {
        const newBrewery = await postBreweryBeers({
          name: tastingInfo.breweryName,
          country: tastingInfo.countryId ? tastingInfo.countryId : 75,
        });
        tastingInfo.breweryId = newBrewery.id;
      } catch (error) {
        setRatingPostLoading(LoadingState.ERROR);
        return;
      }
    }
    if (tastingInfo.beerId == null) {
      // post a new beer
      try {
        const newBeer = await postBeer({
          name: tastingInfo.beerName,
          brewery: tastingInfo.breweryId,
          style:
            tastingInfo.subStyleId !== null
              ? tastingInfo.subStyleId!
              : tastingInfo.styleId!,
          abv: tastingInfo.abv!,
          ibu: tastingInfo.ibu,
          color: tastingInfo.color!,
        });

        tastingInfo.beerId = newBeer.id;
      } catch (error) {
        setRatingPostLoading(LoadingState.ERROR);
        return;
      }
    }

    //post the form
    try {
      const newReview = await postReview({
        beer: tastingInfo.beerId,
        appearance: 0,
        smell: 0,
        taste: 0,
        aftertaste: 0,
        score: comment.score!,
        serving: 0,
        comment: comment.comment,
      });
      setRatingPostLoading(LoadingState.SUCCESS);
    } catch (error) {
      setRatingPostLoading(LoadingState.ERROR);
      return;
    }
  };

  const formPage = (page: number) => {
    switch (page) {
      case 0:
        return (
          <TastinInfo
            handleNext={handleNext}
            tastingInfoValues={tastingInfo}
            setTastingInfoValues={setTastingInfo}
            isPreFilled={isPreFilled}
            next={handleNext}
            resetTastingInfo={resetTastingInfo}
          />
        );
      case 1:
        return <Details next={handleNext} back={handleBack} />;
      case 2:
        return (
          <Comment
            commentDefaultValue={defaultCommentValues}
            back={handleBack}
            handleReviewSubmit={handlePost}
          />
        );
    }
  };

  return (
    <DefaultLayout appBar leftButton>
      <Box
        sx={{
          marginTop: '40px',
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Paper
          sx={{
            ...defaultPaper,
            width: '100%',
            marginX: '5vh',
            maxWidth: '80vh',
          }}
        >
          {ratingPostLoading === LoadingState.SUCCESS ? (
            <Alert severity="success">
              <p>
                Thanks for your review of{' '}
                <a href={'/beers/' + tastingInfo.beerId}>
                  {tastingInfo.beerName}
                </a>
              </p>
            </Alert>
          ) : (
            <>
              <Stepper
                activeStep={activeStep}
                alternativeLabel
                sx={{ width: '100%' }}
              >
                <Step key={0}>
                  <StepLabel>Beer Data</StepLabel>
                </Step>
                <Step key={1}>
                  <StepLabel>
                    Tasting Details <br /> (incoming)
                  </StepLabel>
                </Step>
                <Step key={2}>
                  <StepLabel>Submit Review</StepLabel>
                </Step>
              </Stepper>
              <React.Fragment>
                {beerLoading === LoadingState.SUCCESS && formPage(activeStep)}
              </React.Fragment>
            </>
          )}
        </Paper>
      </Box>
    </DefaultLayout>
  );
};

export default Rate;
