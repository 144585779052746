import { useParams } from 'react-router-dom';
import DefaultLayout from '../../../Ui/Layouts/defaultLayout';
import ProfileHeader from '../Components/ProfileHeader';
import ProfileContent from '../Components/ProfileContent';
import React from 'react';
import { User } from '../types/user';
import { getUser } from '../services/api';
import { Box } from '@mui/material';
import NotFound from '../../Home/Pages/notFound';

const UserProfile = () => {
  const { username: usernameParam } = useParams();
  if (usernameParam == undefined) return <DefaultLayout appBar leftButton />;
  const username: string = usernameParam;

  const [user, setUser] = React.useState<User | undefined>();
  React.useEffect(() => {
    try {
      getUser(username).then((data) => {
        setUser(data);
        document.title = `Zythogora | ${username}`;
      });
    } catch {}
  }, []);

  return user ? (
    <DefaultLayout appBar leftButton>
      <Box id="profile">
        <ProfileHeader user={user} />
        {user.ratings > 0 && <ProfileContent user={user} />}
      </Box>
    </DefaultLayout>
  ) : (
    <NotFound />
  );
};

export default UserProfile;
