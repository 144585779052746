import { Box, Button, Stack, Typography } from '@mui/material';
import './form.css';
import './tastingInfo.css';

interface props {
  next: () => void;
  back: () => void;
}

const Details = ({ next, back }: props) => {
  return (
    <Stack
      sx={{ width: '100%' }}
      direction="column"
      justifyContent="space-evenly"
      alignItems="center"
      spacing={2}
    >
      <Typography sx={{ width: '100%', marginTop: '40px' }}>
        Comming Soon !
      </Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          pt: 2,
        }}
      >
        <Button
          color="inherit"
          onClick={back}
          variant="outlined"
          sx={{ mr: 1 }}
        >
          Back
        </Button>

        <Button onClick={next} variant="contained">
          Next
        </Button>
      </Box>
    </Stack>
  );
};

export default Details;
