import { createTheme } from '@mui/material/styles';
import { orange, red } from '@mui/material/colors';

const zythoTheme = createTheme({
  status: {
    danger: red[500],
  },
  palette: {
    primary: {
      main: '#ffb300',
    },
  },
});

export default zythoTheme;
