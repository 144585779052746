import { Box } from '@mui/material';
import Element from '../Components/Element';

const Updates = () => {
  return (
    <Box>
      <Element
        release="alpha-v0.0.4"
        title="User Profiles 🥳"
        date={'3 Apr 2022'}
        description={[
          'User profiles are here!',
          "Check what you've been drinking lately, get a small preview of what your last month looked like, see what your favorite beers are and who you think the best brewer is!",
        ]}
        features={[
          'Added user profiles',
          'The rating page has been updated: you can add a comment to your review and the score is now out of 10 instead of out of 5',
        ]}
        bugs={[
          'The country is now getting reset with the other fields in the rating form',
          'The "+" button on a beer page is now working as expected',
          'You can no longer type "+", "-" or "e" in ABV and IBU fields and browser autocompletion have been disable for them',
          'The average score is now limited to one decimal on the brewery page',
        ]}
      />
      <Element
        release="alpha-v0.0.3"
        title="The Rating Page got its first major update"
        date={'31 Mar 2022'}
        description={[
          'We have added auto completion on the rating page!',
          'You can now start typing the name of a beer and click on it to fill everything for you (works for breweries as well).',
        ]}
        bugs={[
          'The page title was not working properly',
          "The link 'Zythogora' in the top bar now behave like a normal link (you can right click it, use your middle click on it, etc.)",
          'Scores are now displayed out of 10 instead of out of 5',
          'The search bar was redirecting to the first result everytime, even if you clicked on another one',
          'The name of the country does not disappear anymore when leaving the field on the rating form',
          'The API was too lax on the values it would accept',
        ]}
      />
      <Element
        release="alpha-v0.0.2"
        title="The menu is getting a fresh look"
        date={'22 Mar 2022'}
        description={[
          'Login, important links, sneak pic of the future, all that in one place!',
        ]}
        bugs={['The rating form can now be reset']}
      />
      <Element
        release="alpha-v0.0.1"
        title="Bug fixes"
        date={'20 Mar 2022'}
        bugs={[
          'No need to refresh the page anymore after logging in or out',
          'The rating form now accepts decimal numbers for ABV',
          'Remove front-end filtering for the search bar',
          'Pressing `Enter` while typing inside the search bar now redirects to the first result page',
        ]}
      />
      <Element
        release="alpha-v0.0.0"
        title="Zythogora is live!"
        date={'17 Mar 2022'}
        description="You can give us your feedback by messaging us on Twitter @Zythogora or by sending us an email zythogora@gmail.com."
      />
    </Box>
  );
};

export default Updates;
