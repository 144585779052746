import axios from 'axios';
import { API } from '../../../Tech/config/api';
import { Beer, IBeer } from '../Types/beer';
import { AverageScore } from '../Types/averageScore';
import { Review } from '../Types/review';
import {
  IRating,
  Rating,
  ReviewRatingComment,
  ReviewRatingDetails,
  ReviewRatingTastingInfo,
} from '../Types/rating';

export const getBeer = async (beerId: number): Promise<Beer> => {
  const response = await API.get<Beer>('beers/' + beerId);
  if (response.status !== 200) {
    throw new Error("something wen't wrong");
  }
  return response.data;
};

export const getBeerAverageScore = async (
  beerId: number,
): Promise<AverageScore> => {
  const response = await API.get<AverageScore>(
    'beers/' + beerId + '/averageScore',
  );
  if (response.status !== 200) {
    throw new Error("something wen't wrong");
  }
  return response.data;
};

export const getBeerReviews = async (beerId: number): Promise<Review[]> => {
  const response = await API.get<Review[]>('beers/' + beerId + '/ratings');
  if (response.status !== 200) {
    throw new Error("something wen't wrong");
  }
  return response.data;
};

export const postBeer = async (data: IBeer): Promise<Beer> => {
  const response = await API.post<Beer>('/beers', data);
  if (response.status !== 200 && response.status !== 409) {
    throw new Error("something wen't wrong");
  }
  return response.data;
};

export const postReview = async (data: IRating): Promise<Rating> => {
  const response = await API.post<Rating>('/ratings', data);
  if (response.status !== 200) {
    throw new Error("something wen't wrong");
  }
  return response.data;
};

export const searchBeer = async (
  input: string,
  count: number,
  page: number,
): Promise<Beer[]> => {
  const response = await API.get<Beer[]>(
    `beers/search/${input}?count=${count}&page=${page}`,
  );
  if (response.status !== 200) {
    throw new Error("something wen't wrong");
  }
  return response.data;
};
