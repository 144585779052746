import { Box } from '@mui/material';
import './ProfileContent.css';
import './FavoriteBeer.css';
import { Beer } from '../../Beer/Types/beer';

interface props {
  rank: number;
  beer: Beer;
}

const FavoriteBeer = ({ rank, beer }: props) => {
  return (
    <Box className="favorite-element">
      <Box className="favorite-element-rank">#{rank}</Box>

      {beer.color ? (
        <Box
          className="favorite-beer-color"
          style={{ backgroundColor: `#${beer.color.color}` }}
        ></Box>
      ) : (
        <Box></Box>
      )}

      <a href={`/beers/${beer.id}`} className="favorite-element-name">
        {beer.name}
      </a>
    </Box>
  );
};

export default FavoriteBeer;
