import * as React from 'react';
import DefaultLayout from '../../../Ui/Layouts/defaultLayout';

const NotFound = () => {
  const [element, setElement] = React.useState(<></>);

  setTimeout(function () {
    setElement(<h1 style={{ marginTop: '50px' }}>Page notFound ...</h1>);
  }, 1000);

  return (
    <DefaultLayout appBar leftButton>
      {element}
    </DefaultLayout>
  );
};

export default NotFound;
