import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { Routes, Route } from 'react-router-dom';
import Home from './Home/Pages';
import WhatsNew from './WhatsNew';
import Login from './User/Pages/login';
import RoutePaths from './routes';
import Register from './User/Pages/register';
import BeerPage from './Beer/Pages';
import BreweryPage from './Brewery/Pages';
import Rate from './Beer/Pages/Rate';
import NotFound from './Home/Pages/notFound';
import UserProfile from './User/Pages';

function ZythoRouter() {
  return (
    <div className="App">
      <Routes>
        <Route path={RoutePaths.HOME} element={<Home />} />
        <Route path={RoutePaths.WHATSNEW} element={<WhatsNew />} />
        <Route path={RoutePaths.LOGIN} element={<Login />} />
        <Route path={RoutePaths.REGISTER} element={<Register />} />
        <Route path={RoutePaths.USER_PROFILE} element={<UserProfile />} />
        <Route path={RoutePaths.BEER} element={<BeerPage />} />
        <Route path={RoutePaths.BREWERY} element={<BreweryPage />} />
        <Route path={RoutePaths.RATE}>
          <Route index element={<Rate />} />
          <Route path={RoutePaths.RATE_ID} element={<Rate />} />
        </Route>
        <Route path={'*'} element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default ZythoRouter;
