import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { Review } from '../../Beer/Types/review';
import { getUserReviewsSince } from '../services/api';
import { User } from '../types/user';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import './ProfileContent.css';
import './LastMonth.css';

interface props {
  user: User;
}

const colorToBinaryInvert = (hex: string) => {
  let r = 255 - parseInt('0x' + hex[0] + hex[1], 16);
  let g = 255 - parseInt('0x' + hex[2] + hex[3], 16);
  let b = 255 - parseInt('0x' + hex[4] + hex[5], 16);

  let cmin = Math.min(r, g, b);
  let cmax = Math.max(r, g, b);

  let l = (cmax + cmin) / 2;

  return l > 127 ? 'white' : 'black';
};

const LastMonth = ({ user }: props) => {
  const [reviews, setReviews] = React.useState<Review[]>();
  React.useEffect(() => {
    try {
      getUserReviewsSince(user.username, '4W').then((data) => setReviews(data));
    } catch {}
  }, []);

  const day = 86400000;
  let currentDay = new Date().getTime() - 27 * day + 3600000;

  return reviews ? (
    <Box id="last-month" className="content-element">
      <Box className="content-title">
        <h1>Last Month</h1>
      </Box>
      <Box id="last-month-grid">
        {[...Array(4)].map((x, w) =>
          [...Array(7)].map((x, d) => {
            const currentDate = new Date(currentDay);
            const currentDateDay = currentDate.getDate();
            const currentDateMonth = new Intl.DateTimeFormat(
              navigator.language,
              {
                month: 'short',
              },
            ).format(currentDate);

            const review = reviews.filter(
              (review) =>
                new Date(review.date).setHours(0, 0, 0, 0) ===
                new Date(currentDay).setHours(0, 0, 0, 0),
            );
            currentDay = currentDay + day;

            if (review.length == 0)
              return (
                <Box key={7 * w + d} className="last-month-element">
                  <h1>{currentDateDay}</h1>
                  <h1>{currentDateMonth}</h1>
                </Box>
              );

            let colors: { [color: string]: number } = {};
            review.map((r) => {
              if (r.beer.color) {
                const color = r.beer.color.color;
                if (color in colors) colors[color] += 1;
                else colors[color] = 1;
              }
            });
            const color = Object.keys(colors).find(
              (key) =>
                colors[key] === Math.max.apply(null, Object.values(colors)),
            );

            return (
              <Tooltip
                enterTouchDelay={0}
                key={7 * w + d}
                title={review.map((r) => (
                  <p
                    key={(7 * w + d) * r.beer.id}
                    style={{ textAlign: 'center' }}
                  >
                    {r.beer.name}
                  </p>
                ))}
              >
                <Box
                  className="last-month-element last-month-element-exists"
                  style={{
                    backgroundColor: `#${color}`,
                    color: `${
                      (color && colorToBinaryInvert(color)) || 'black'
                    }`,
                  }}
                >
                  <h1>{currentDateDay}</h1>
                  <h1>{currentDateMonth}</h1>
                </Box>
              </Tooltip>
            );
          }),
        )}
      </Box>
    </Box>
  ) : (
    <Box></Box>
  );
};

export default LastMonth;
