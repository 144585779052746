import * as React from 'react';
import DefaultLayout from '../../Ui/Layouts/defaultLayout';
import { Box, Tab, Tabs } from '@mui/material';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import WorkIcon from '@mui/icons-material/Work';
import Updates from './Pages/Updates';
import Roadmap from './Pages/Roadmap';
import './index.css';

const WhatsNew = () => {
  const [tab, setTab] = React.useState('updates');

  const ChangeTab = (event: React.SyntheticEvent, value: string) => {
    setTab(value);
  };

  return (
    <DefaultLayout appBar leftButton>
      <Box
        sx={{
          width: 'min(900px, 100%)',
          margin: '50px auto 0 auto',
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
          }}
        >
          <Tabs
            value={tab}
            onChange={ChangeTab}
            centered
            textColor="primary"
            indicatorColor="primary"
            variant="fullWidth"
          >
            <Tab
              value="updates"
              label="New Updates"
              icon={<NewReleasesIcon />}
            />
            <Tab value="roadmap" label="Roadmap" icon={<WorkIcon />} />
          </Tabs>
        </Box>
        {tab === 'updates' && <Updates />}
        {tab === 'roadmap' && <Roadmap />}
      </Box>
    </DefaultLayout>
  );
};

export default WhatsNew;
