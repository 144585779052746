import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import PersonIcon from '@mui/icons-material/Person';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LanguageIcon from '@mui/icons-material/Language';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import SettingsIcon from '@mui/icons-material/Settings';
import Login from '@mui/icons-material/Login';
import Logout from '@mui/icons-material/Logout';
import { ListItem } from '@mui/material';
import { useAuth } from '../../Tech/Auth/provider';
import { useLocation, useNavigate } from 'react-router-dom';

interface props {
  element: HTMLElement | null;
  close: () => void;
  opened: boolean;
}

const AppBarMenu = ({ element, close, opened }: props) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const path = useLocation();

  const navigateToRate = () => {
    if (path.pathname.includes('/rate')) {
      if (!path.pathname.endsWith('/rate')) navigate('/rate');
      window.location.reload();
    } else {
      navigate('/rate');
    }
  };

  return (
    <Menu
      anchorEl={element}
      id="menu"
      open={opened}
      onClose={close}
      onClick={close}
      PaperProps={{
        elevation: 0,
        sx: {
          maxWidth: '240px',
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 20,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      disableScrollLock={true}
    >
      {auth.authTokenData && (
        <ListItem key="menu-username" onClick={() => {}}>
          Welcome {auth.authTokenData.nickname}!
        </ListItem>
      )}

      <MenuItem
        key="menu-releases"
        onClick={() => {
          navigate('/whatsnew');
        }}
      >
        <ListItemIcon>
          <NewReleasesIcon fontSize="small" />
        </ListItemIcon>
        What's new?
      </MenuItem>
      <Divider key="menu-divider-1" />

      {auth.authTokenData && [
        <MenuItem
          key="menu-profile"
          onClick={() => {
            auth.authTokenData &&
              navigate(`/users/${auth.authTokenData.nickname}`);
          }}
        >
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          My Profile
        </MenuItem>,
        <MenuItem
          key="menu-review"
          onClick={() => {
            navigateToRate();
          }}
        >
          <ListItemIcon>
            <AddCircleOutlineIcon fontSize="small" />
          </ListItemIcon>
          New review
        </MenuItem>,
        <Divider key="menu-divider-2" />,
      ]}

      <MenuItem key="menu-language" disabled>
        <ListItemIcon>
          <LanguageIcon fontSize="small" />
        </ListItemIcon>
        Language
      </MenuItem>

      <MenuItem key="menu-darkmode" disabled>
        <ListItemIcon>
          <DarkModeIcon fontSize="small" />
        </ListItemIcon>
        Dark Mode
      </MenuItem>

      {auth.authTokenData
        ? [
            <MenuItem key="menu-preferences" disabled>
              <ListItemIcon>
                <SettingsIcon fontSize="small" />
              </ListItemIcon>
              Preferences
            </MenuItem>,

            <MenuItem
              key="menu-logout"
              onClick={() => {
                auth.authContextLogout();
                navigate('/');
              }}
            >
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>,
          ]
        : [
            <MenuItem
              key="menu-login"
              onClick={() => {
                navigate('/login');
              }}
            >
              <ListItemIcon>
                <Login fontSize="small" />
              </ListItemIcon>
              Login
            </MenuItem>,
          ]}
    </Menu>
  );
};

export default AppBarMenu;
