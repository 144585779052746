import { Box, LinearProgress } from '@mui/material';
import * as React from 'react';
import './index.css';
import { useParams } from 'react-router-dom';
import DefaultLayout from '../../../Ui/Layouts/defaultLayout';
import { LoadingState } from '../../../Tech/Types/loadingStates';
import BreweryHeader from '../Components/BreweryHeader';
import BreweryBeers from '../Components/BreweryBeers';

const BreweryPage = () => {
  const { breweryId } = useParams();
  if (breweryId == undefined) return <DefaultLayout appBar leftButton />;
  const id: number = parseInt(breweryId);

  const [loadingState, setLoadingState] = React.useState(LoadingState.TO_START);

  return (
    <DefaultLayout appBar leftButton>
      <>
        {loadingState === LoadingState.LOADING && (
          <Box marginY={0} width="100%">
            <LinearProgress sx={{ height: '6px' }} />
          </Box>
        )}
      </>

      <div id="brewery-content">
        <BreweryHeader breweryId={id} stateCallback={setLoadingState} />
        <BreweryBeers breweryId={id} />
      </div>
    </DefaultLayout>
  );
};

export default BreweryPage;
