import { Box } from '@mui/material';
import { Brewery } from '../../Brewery/Types/brewery';

interface props {
  rank: number;
  brewery: Brewery;
}

const FavoriteBrewery = ({ rank, brewery }: props) => {
  return (
    <Box className="favorite-element">
      <Box className="favorite-element-rank">#{rank}</Box>

      <img
        className="favorite-brewery-flag"
        loading="lazy"
        width="15px"
        src={`https://flagcdn.com/w40/${brewery.country.flag.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/w80/${brewery.country.flag.toLowerCase()}.png 2x`}
        alt=""
      />

      <a href={`/breweries/${brewery.id}`} className="favorite-element-name">
        {brewery.name}
      </a>
    </Box>
  );
};

export default FavoriteBrewery;
