import AuthToken from './type';
import { AuthTokenData } from '../../Business/User/types/authTokenData';
import React, { useContext } from 'react';
import jwt_decode from 'jwt-decode';
import { API } from './../config/api';

let authResult: AuthToken | null = null;
let accessToken: string | null = null;
let authTokenData: AuthTokenData | null = null;

interface AuthContextData {
  authTokenData: AuthTokenData | null;
  authContextLogin: (authTokenData: AuthTokenData) => void;
  authContextLogout: () => void;
}

export const AuthContext = React.createContext<AuthContextData>({
  authTokenData: null,
  authContextLogin: (authTokenData: AuthTokenData) => {},
  authContextLogout: () => {},
});

export const useAuth = () => {
  return useContext(AuthContext);
};

export const isAuthenticated = () => {
  return !!authResult;
};

export const getAuthResult = () => {
  return authResult;
};

export const getAuthTokenData = () => {
  return authTokenData;
};

export const getAccessToken = () => {
  return accessToken;
};

export const getAuthTokenDataLS = (): AuthTokenData | null => {
  const userInStore = localStorage.getItem('authTokenData');
  if (userInStore) {
    return JSON.parse(userInStore);
  }
  return null;
};

export const getAccessTokenLS = (): string | null => {
  const authTokenInStore = localStorage.getItem('authToken');
  if (authTokenInStore) {
    return JSON.parse(authTokenInStore);
  }
  return null;
};

export const persistAuth = (token: string) => {
  accessToken = token;
  localStorage.setItem('authToken', accessToken);
  authResult = jwt_decode(token);
  if (authResult === null) {
    throw new Error('Could not decode token');
  }
  authTokenData = {
    clientId: authResult.clientId,
    nickname: authResult.nickname,
  };
  localStorage.setItem('authTokenData', JSON.stringify(authTokenData));
  return authResult;
};

export const unpersistAuth = () => {
  authResult = null;
  accessToken = null;
  authTokenData = null;
  localStorage.removeItem('authToken');
  localStorage.removeItem('authTokenData');
};

export const renewToken = () => {
  return;
};

export const isAuthLocalStorage = () => {
  const token = localStorage.getItem('authToken');
  if (token) {
    persistAuth(token);
  }
};
