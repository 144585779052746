import { API } from '../config/api';
import { Country } from '../Types/countries';

export const getCountries = async (): Promise<Country[]> => {
  const response = await API.get<Country[]>('/countries');
  if (response.status !== 200) {
    throw new Error("something wen't wrong");
  }
  return response.data;
};
