import { Box } from '@mui/material';
import { useAuth } from '../../../Tech/Auth/provider';
import { User } from '../types/user';
import FavoriteBeers from './FavoriteBeers';
import FavoriteBreweries from './FavoriteBreweries';
import './Favorites.css';

interface props {
  user: User;
}

const Favorites = ({ user }: props) => {
  const auth = useAuth();

  return (
    <Box className="content-element">
      <Box className="content-title">
        <h1>
          {(auth.authTokenData &&
            auth.authTokenData.nickname == user.username &&
            'Your Favorites') ||
            `${user.username}'${
              user.username.endsWith('s') ? '' : 's'
            } Favorites`}
        </h1>
      </Box>

      <Box id="favorites">
        <FavoriteBeers user={user} />
        <FavoriteBreweries user={user} />
      </Box>
    </Box>
  );
};

export default Favorites;
