import { API } from '../../../Tech/config/api';
import { Color } from '../Types/colors';
import { Style } from '../Types/styles';

async function getById(id: number): Promise<number> {
  return 1;
}

export const beerService = {
  getById,
};

export const getStyles = async (): Promise<Style[]> => {
  const response = await API.get<Style[]>('/styles');
  if (response.status !== 200) {
    throw new Error("something wen't wrong");
  }
  return response.data;
};

export const getColors = async (): Promise<Color[]> => {
  const response = await API.get<Color[]>('/colors');
  if (response.status !== 200) {
    throw new Error("something wen't wrong");
  }
  return response.data;
};
