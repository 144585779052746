import {
  Alert,
  Box,
  Button,
  CircularProgress,
  LinearProgress,
  Paper,
  TextField,
} from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler, useFormState } from 'react-hook-form';
import { persistAuth, useAuth } from '../../../../Tech/Auth/provider';
import DefaultLayout from '../../../../Ui/Layouts/defaultLayout';
import { login } from '../../services/api';
import { LoginValues } from '../../types/login';
import defaultPaper from '../../../../Ui/Layouts/defaultPaper';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingState } from '../../../../Tech/Types/loadingStates';

const shemaLogin = yup
  .object({
    username: yup.string().required('Username is required'),
    password: yup.string().required('Password is required'),
  })
  .required();

const Login = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [loadingState, setLoadingState] = React.useState(LoadingState.TO_START);
  const [errorMessage, setErrorMessage] = React.useState('');

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<LoginValues>({
    resolver: yupResolver(shemaLogin),
  });

  const navigate = useNavigate();
  const auth = useAuth();

  const onSubmit = async (data: LoginValues) => {
    setLoadingState(LoadingState.LOADING);
    try {
      const result = await login({
        username: data.username,
        password: data.password,
      });
      const authResult = persistAuth(result.token);
      auth.authContextLogin({
        nickname: authResult.nickname,
        clientId: authResult.clientId,
      });
      setLoadingState(LoadingState.SUCCESS);
      navigate('/');
    } catch {
      // TODO: create custom errors
      setErrorMessage('something went wrong');
      setLoadingState(LoadingState.ERROR);
    }
  };

  return (
    <DefaultLayout appBar leftButton={false}>
      <form className="flex column" onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            marginTop: '40px',
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Paper sx={defaultPaper}>
            <TextField
              error={!!errors.username}
              helperText={errors.username ? errors.username.message : undefined}
              label="Username"
              {...register('username', { required: true })}
              id="login-username"
              sx={{ m: 1, width: '90%' }}
              size="small"
              variant="standard"
            />

            <TextField
              error={!!errors.password}
              helperText={errors.password ? errors.password.message : undefined}
              label="Password"
              {...register('password', { required: true })}
              id="login-password"
              sx={{ m: 1, width: '90%' }}
              type={'password'}
              size="small"
              variant="standard"
            />
            <Box mt={2}>
              <Button variant="contained" type="submit">
                Login
              </Button>
            </Box>

            <Box marginY={2}>
              <Button variant="outlined" onClick={() => navigate('/register')}>
                Register ?
              </Button>
            </Box>

            {loadingState === LoadingState.ERROR && (
              <Box m={2}>
                <Alert className="mt-20" severity="error">
                  {errorMessage}
                </Alert>
              </Box>
            )}

            {loadingState === LoadingState.LOADING && (
              <Box marginY={2} width="90%">
                <LinearProgress />
              </Box>
            )}
          </Paper>
        </Box>
      </form>
    </DefaultLayout>
  );
};

export default Login;
