import React from 'react';
import DefaultAppBar from '../AppBar/defaultAppBar';

interface layoutProps {
  appBar: boolean;
  leftButton: boolean;
  children?: JSX.Element | JSX.Element[] | null;
}

const defaultLayout: React.FC<layoutProps> = ({
  appBar,
  leftButton,
  children,
}: layoutProps) => {
  document.title = 'Zythogora';

  return (
    <div className="Layout">
      <DefaultAppBar leftButton={leftButton} />
      {children}
    </div>
  );
};

export default defaultLayout;
