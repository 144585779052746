import { Box } from '@mui/material';
import { Time } from '../../../Tech/config/time';
import './Element.css';

interface props {
  release?: string;
  title: string;
  date?: string;
  description?: string | string[];
  features?: string[];
  bugs?: string[];
}

const Element = ({
  release = '',
  title,
  date,
  description,
  features,
  bugs,
}: props) => {
  return (
    <Box id={release} className="element">
      <h1 className="element-title">{title}</h1>
      {release && <p className="element-release">{release}</p>}
      {date && (
        <p className="element-date">
          {Time(new Date(Date.parse(date))).format('Do [of] MMMM YYYY')}
        </p>
      )}
      {description &&
        (description instanceof Array ? (
          description.map((item) => {
            return (
              <p
                key={Math.floor(Math.random() * 10000000)}
                className="element-description"
              >
                {item}
              </p>
            );
          })
        ) : (
          <p className="element-description">{description}</p>
        ))}
      {features && (
        <ul className="element-features">
          {features.map((item) => {
            return <li key={Math.floor(Math.random() * 10000000)}>{item}</li>;
          })}
        </ul>
      )}
      {bugs && (
        <Box className="element-bugs">
          <h2>Bugs fixed in this update:</h2>
          <ul>
            {bugs.map((item) => {
              return <li key={Math.floor(Math.random() * 10000000)}>{item}</li>;
            })}
          </ul>
        </Box>
      )}
    </Box>
  );
};

export default Element;
