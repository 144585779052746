import axios, { AxiosRequestConfig } from 'axios';
import { getAccessToken, isAuthenticated } from '../Auth/provider';

const axiosInstance = axios.create({
  baseURL: 'https://api.zythogora.com/',
  timeout: 5000,
  validateStatus: (status: number) => status >= 200 && status < 500,
});

const getTokenString = () => {
  const token = getAccessToken();
  if (token === null) {
    return 'null';
  }
  return token;
};

// Request interceptor for API calls
axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  if (config.headers !== undefined && isAuthenticated()) {
    config.headers['Authorization'] = getTokenString();
  } else {
    config.headers = {};
  }
  return config;
});

// Response interceptor for API calls
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      //refresh token
      //return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  },
);

export { axiosInstance as API };
