import { Box, Button, Grid, Slider, Stack, TextField } from '@mui/material';
import * as yup from 'yup';
import './form.css';
import './tastingInfo.css';
import './form.css';
import { useForm } from 'react-hook-form';
import { ReviewRatingComment } from '../../../Types/rating';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';

const schemaRatingComment = yup
  .object({
    comment: yup.string().optional(),
    score: yup.number().required('Is required'),
  })
  .required();

interface props {
  commentDefaultValue: ReviewRatingComment;
  back: () => void;
  handleReviewSubmit: (comment: ReviewRatingComment) => void;
}

const Comment = ({ commentDefaultValue, back, handleReviewSubmit }: props) => {
  const {
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<ReviewRatingComment>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: commentDefaultValue,
    resolver: yupResolver(schemaRatingComment),
    criteriaMode: 'all',
    shouldFocusError: false,
  });

  const [sliderValue, setSliderValue] = useState(getValues('score'));
  const [comment, setComment] = useState(getValues('comment'));

  useEffect(() => {
    if (sliderValue) setValue('score', sliderValue);
  }, [sliderValue]);

  const handleChangeComment = (
    value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setComment(value.target.value);
    setValue('comment', value.target.value);
  };

  const handleChangeScore = (event: any, newValue: number | number[]) => {
    setSliderValue(newValue as number);
    setValue('score', newValue as number);
  };

  const onSubmit = async (data: ReviewRatingComment) => {
    handleReviewSubmit(data);
  };

  return (
    <form>
      <Stack
        sx={{ width: '100%' }}
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={2}
      >
        <Grid container spacing={2} width="90%" marginY={2}>
          <Grid item xs={12} justifyContent="center" marginTop={2}>
            <TextField
              id="filled-multiline-static"
              label="Comment (Optional)"
              multiline
              rows={8}
              fullWidth
              variant="filled"
              value={comment}
              onChange={handleChangeComment}
            />
          </Grid>
          <Grid item xs={12} justifyContent="center" marginTop={2}>
            <div
              id={!!errors.score ? 'form-color-error' : 'form-color-section'}
            >
              {' '}
              Score{' '}
            </div>

            <Grid
              id="score-slider"
              item
              container
              md={12}
              justifyContent="center"
              marginTop={2}
            >
              <Slider
                id="slider"
                min={0}
                max={10}
                step={0.5}
                marks
                value={sliderValue}
                onChange={handleChangeScore}
              />
              <h4 id="slider-value">{sliderValue}</h4>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            pt: 2,
          }}
        >
          <Button
            color="inherit"
            onClick={back}
            variant="outlined"
            sx={{ mr: 1 }}
          >
            Back
          </Button>

          <Button variant="contained" onClick={handleSubmit(onSubmit)}>
            Finish
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default Comment;
