import axios from 'axios';
import { API } from '../../../Tech/config/api';
import { Beer } from '../../Beer/Types/beer';
import { Review } from '../../Beer/Types/review';
import { Brewery } from '../../Brewery/Types/brewery';
import { ILoginResponse, LoginValues } from '../types/login';
import { RegisterValues } from '../types/register';
import { User } from '../types/user';

export const login = async (values: LoginValues): Promise<ILoginResponse> => {
  const response = await API.post<ILoginResponse>('users/login', values);
  if (response.status !== 200) {
    throw new Error("something wen't wrong");
  }
  return response.data;
};

export const register = async (
  values: RegisterValues,
): Promise<ILoginResponse> => {
  const response = await API.post<ILoginResponse>('users/register', values);
  if (response.status !== 200) {
    throw new Error("something wen't wrong");
  }
  return response.data;
};

export const getUser = async (username: string): Promise<User> => {
  const response = await API.get<User>(`users/${username}`);
  if (response.status !== 200) {
    throw new Error("something wen't wrong");
  }
  return response.data;
};

export const getUserFavoriteBeers = async (username: string): Promise<Beer[]> => {
  const response = await API.get<Beer[]>(`top/beers/${username}?count=5`);
  if (response.status !== 200) {
    throw new Error("something wen't wrong");
  }
  return response.data;
};

export const getUserFavoriteBreweries = async (username: string): Promise<Brewery[]> => {
  const response = await API.get<Brewery[]>(`top/breweries/${username}?count=5`);
  if (response.status !== 200) {
    throw new Error("something wen't wrong");
  }
  return response.data;
};

export const getUserReviews = async (username: string, count: number, page: number): Promise<Review[]> => {
  const response = await API.get<Review[]>(`users/${username}/ratings?count=${count}&page=${page}`);
  if (response.status !== 200) {
    throw new Error("something wen't wrong");
  }
  return response.data;
};

export const getUserReviewsSince = async (username: string, since: string): Promise<Review[]> => {
  const response = await API.get<Review[]>(`users/${username}/ratings/since/${since}`);
  if (response.status !== 200) {
    throw new Error("something wen't wrong");
  }
  return response.data;
};
