import { Box, Fab, LinearProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import * as React from 'react';
import './index.css';
import { useNavigate, useParams } from 'react-router-dom';
import DefaultLayout from '../../../Ui/Layouts/defaultLayout';
import { LoadingState } from '../../../Tech/Types/loadingStates';
import BeerHeader from '../Components/BeerHeader';
import BeerReviews from '../Components/BeerReviews';

const BeerPage = () => {
  const { beerId } = useParams();
  if (beerId == undefined) return <DefaultLayout appBar leftButton />;
  const id: number = parseInt(beerId);

  const [loadingState, setLoadingState] = React.useState(LoadingState.TO_START);

  const navigate = useNavigate();

  return (
    <DefaultLayout appBar leftButton>
      <>
        {loadingState === LoadingState.LOADING && (
          <Box marginY={0} width="100%">
            <LinearProgress sx={{ height: '6px' }} />
          </Box>
        )}
      </>

      <div id="beer-content">
        <BeerHeader beerId={id} stateCallback={setLoadingState} />
        <BeerReviews beerId={id} />
        <Fab
          id="add-review"
          color="primary"
          onClick={() => navigate(`/rate/${beerId}`)}
          aria-label="add"
        >
          <AddIcon />
        </Fab>
      </div>
    </DefaultLayout>
  );
};

export default BeerPage;
