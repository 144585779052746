import {
  ReviewRatingComment,
  ReviewRatingDetails,
  ReviewRatingTastingInfo,
} from '../Types/rating';

export const defaultTastingInfo: ReviewRatingTastingInfo = {
  beerId: null,
  beerName: '',
  breweryId: null,
  breweryName: '',
  countryId: null,
  countryName: '',
  styleId: 0,
  subStyleId: 0,
  abv: null,
  ibu: null,
  color: null,
  serving: null,
};

export const defaultDetails: ReviewRatingDetails = {
  appearance: 0,
  smell: 0,
  taste: 0,
  aftertaste: 0,
};

export const defaultCommentValues: ReviewRatingComment = {
  comment: '',
  score: 5,
};
