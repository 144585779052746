const defaultPaper = {
  padding: '10px',
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  flexDirection: 'column',
  elevation: '5',
  width: '40vh',
};
export default defaultPaper;
