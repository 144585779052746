import { Box } from '@mui/material';
import Element from '../Components/Element';

const Roadmap = () => {
  return (
    <Box>
      <Element
        title="Rating Form"
        description="Update different parts of the review page."
        features={[
          'Release the second page (appearance, nez, taste and aftertaste) to provide a more detailed review',
          'Rework the style to make it more accurate (not only style / substyle)',
          'Add fields explanation (what is IBU, what means ABV, etc.)',
        ]}
      />
    </Box>
  );
};

export default Roadmap;
