import { Box } from '@mui/material';
import { Time } from '../../../Tech/config/time';
import { Review } from '../Types/review';
import './ReviewComponent.css';

interface props {
  review: Review;
}

const ReviewComponent = ({ review }: props) => {
  return (
    <Box className="review">
      <Box className="review-header">
        <a href={`/users/${review.user.username}`} className="review-author">
          {review.user.username}
        </a>
        <Box className="review-date">
          on the {Time(review.date).format('Do [of] MMMM YYYY')}
        </Box>
      </Box>

      <p className="review-comment">{review.comment}</p>

      <Box className="review-rating">
        <h1 className="review-score">{review.score}</h1>
      </Box>
    </Box>
  );
};

export default ReviewComponent;
