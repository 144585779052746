const RoutePaths = {
  HOME: '/',
  WHATSNEW: '/whatsnew',
  LOGIN: '/login',
  REGISTER: '/register',
  USER_PROFILE: '/users/:username',
  BEER: '/beers/:beerId',
  BREWERY: '/breweries/:breweryId',
  RATE_ID: ':beerId',
  RATE: '/rate',
};

export default RoutePaths;
