import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import BarChartIcon from '@mui/icons-material/BarChart';
import { User } from '../types/user';
import Feed from './Feed';
import Favorites from './Favorites';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import LastMonth from './LastMonth';

interface props {
  user: User;
}

const ProfileContent = ({ user }: props) => {
  const [selectedTab, setSelectedTab] = useState(1);
  const [showFavorites, setShowFavorites] = useState(true);
  const [showFeed, setShowFeed] = useState(true);
  const [showMore, setShowMore] = useState(true);

  const changeTab = (id: number) => {
    setSelectedTab(id);
    switch (id) {
      case 0:
        setShowFavorites(true);
        setShowFeed(false);
        setShowMore(false);
        break;
      case 1:
        setShowFavorites(false);
        setShowFeed(true);
        setShowMore(false);
        break;
      case 2:
        setShowFavorites(false);
        setShowFeed(false);
        setShowMore(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isMobile && window.innerWidth <= 800) {
      changeTab(1);
    }
  }, []);

  return (
    <Box id="content">
      {showFavorites && <Favorites user={user} />}
      {showFeed && <Feed user={user} />}
      {showMore && (
        <Box id="content-right">
          <LastMonth user={user} />
        </Box>
      )}

      {isMobile && window.innerWidth <= 800 && (
        <Paper
          id="mobile-navigation"
          sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={selectedTab}
            onChange={(e, value) => {
              changeTab(value);
            }}
          >
            <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
            <BottomNavigationAction
              label="History"
              icon={<DynamicFeedIcon />}
            />
            <BottomNavigationAction label="More" icon={<BarChartIcon />} />
          </BottomNavigation>
        </Paper>
      )}
    </Box>
  );
};

export default ProfileContent;
