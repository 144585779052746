import * as React from 'react';
import { getBeer } from '../Services/api';
import { Beer } from '../Types/beer';
import { LoadingState } from '../../../Tech/Types/loadingStates';
import { Box } from '@mui/material';
import './BeerHeader.css';

interface props {
  beerId: number;
  stateCallback: (state: LoadingState) => void;
}

const BeerHeader = ({ beerId, stateCallback }: props) => {
  const [beer, setBeer] = React.useState<Beer | undefined>();

  React.useEffect(() => {
    stateCallback(LoadingState.LOADING);
    try {
      getBeer(beerId).then((data) => setBeer(data));
      stateCallback(LoadingState.SUCCESS);
    } catch {
      stateCallback(LoadingState.ERROR);
    }
  }, [beerId]);

  if (beer === undefined) return null;

  document.title = `Zythogora | ${beer.name}`;

  // Nullable fields
  let beerColor = (
    <Box
      id="beer-color"
      style={
        beer.color
          ? { backgroundColor: '#' + beer.color.color }
          : { backgroundColor: '#f7f7f7', borderColor: '#aaa' }
      }
    >
      {beer.color ? null : '?'}
    </Box>
  );
  let beerIbu = beer.ibu ? <p id="beer-ibu">{beer.ibu}</p> : null;

  return (
    <Box id="beer-header">
      {beerColor}

      <h1 id="beer-name">{beer.name}</h1>
      <h1 id="beer-style">{beer.style.name}</h1>

      <p id="brewery-name">
        Brewed by{' '}
        <a href={'/breweries/' + beer.brewery.id}>{beer.brewery.name}</a> (
        <img
          src={`https://flagcdn.com/h40/${beer.brewery.country.flag.toLowerCase()}.png`}
          srcSet={`https://flagcdn.com/h80/${beer.brewery.country.flag.toLowerCase()}.png 2x`}
          alt=""
        />
        )
      </p>

      <Box id="beer-data">
        <p id="beer-abv">{beer.abv}</p>
        {beerIbu}
      </Box>
    </Box>
  );
};

export default BeerHeader;
