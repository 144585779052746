import { Box } from '@mui/material';
import React from 'react';
import { getUserFavoriteBeers } from '../services/api';
import { User } from '../types/user';
import './Favorites.css';
import { Beer } from '../../Beer/Types/beer';
import FavoriteBeer from './FavoriteBeer';

interface props {
  user: User;
}

const FavoriteBeers = ({ user }: props) => {
  const [favoriteBeers, setFavoriteBeers] = React.useState<Beer[]>();
  React.useEffect(() => {
    try {
      getUserFavoriteBeers(user.username).then((data) =>
        setFavoriteBeers(data),
      );
    } catch {}
  }, []);

  return favoriteBeers ? (
    <Box id="favorite-beers">
      <h1 className="favorite-title">Favorite Beers</h1>

      {favoriteBeers.map((beer, id) => (
        <FavoriteBeer rank={id + 1} beer={beer} key={beer.id} />
      ))}
    </Box>
  ) : (
    <Box></Box>
  );
};

export default FavoriteBeers;
