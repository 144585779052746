import { Box } from '@mui/material';
import React from 'react';
import { Brewery } from '../../Brewery/Types/brewery';
import { getUserFavoriteBreweries } from '../services/api';
import { User } from '../types/user';
import FavoriteBrewery from './FavoriteBrewery';
import './Favorites.css';

interface props {
  user: User;
}

const FavoriteBreweries = ({ user }: props) => {
  const [favoriteBreweries, setFavoriteBreweries] = React.useState<Brewery[]>();
  React.useEffect(() => {
    try {
      getUserFavoriteBreweries(user.username).then((data) =>
        setFavoriteBreweries(data),
      );
    } catch {}
  }, []);

  return favoriteBreweries ? (
    <Box id="favorite-breweries">
      <h1 className="favorite-title">Favorite Breweries</h1>

      {favoriteBreweries.map((brewery, id) => (
        <FavoriteBrewery rank={id + 1} brewery={brewery} key={brewery.id} />
      ))}
    </Box>
  ) : (
    <Box></Box>
  );
};

export default FavoriteBreweries;
