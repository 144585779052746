import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Autocomplete, {
  AutocompleteChangeReason,
} from '@mui/material/Autocomplete';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingState } from '../../../../../Tech/Types/loadingStates';
import { ReviewRatingTastingInfo } from '../../../Types/rating';
import { getCountries } from '../../../../../Tech/Services/countries';
import './form.css';
import './tastingInfo.css';
import { Style } from '../../../Types/styles';
import { getColors, getStyles } from '../../../Services';
import { Color } from '../../../Types/colors';
import { Beer } from '../../../Types/beer';
import { searchBeer } from '../../../Services/api';
import { Brewery } from '../../../../Brewery/Types/brewery';
import { searchBrewery } from '../../../../Brewery/Services/api';
import { Country } from '../../../../../Tech/Types/countries';

interface props {
  handleNext: () => void;
  tastingInfoValues: ReviewRatingTastingInfo;
  setTastingInfoValues(value: ReviewRatingTastingInfo): void;
  isPreFilled: boolean;
  next: () => void;
  resetTastingInfo: () => void;
}

const schemaRatingTastinginfo = yup
  .object({
    beerName: yup.string().required('Is required'),
    breweryName: yup.string().required('Is required'),
    countryName: yup.string().required('Is required').notOneOf(['']),
    styleId: yup
      .number()
      .typeError('Please select a style')
      .moreThan(0, 'Please select a style')
      .required('Is required'),
    subStyleId: yup.number().nullable(true),
    abv: yup.number().typeError('Must be a number').required('Is required'),
    ibu: yup.number().typeError('Must be a number').nullable(true),
    color: yup.number().typeError('Please select a color').nullable(true),
  })
  .required();

const TastinInfo = ({
  tastingInfoValues,
  setTastingInfoValues,
  isPreFilled,
  next,
  resetTastingInfo,
}: props) => {
  const [currentStyle, setCurrentStyle] = useState<Style | null>(null);
  const [currentSubStyle, setCurrentSubStyle] = useState<Style | null>(null);
  const [countries, setCountries] = useState<Country[]>([]);
  const [colors, setColors] = useState<Color[]>([]);
  const [styles, setStyles] = useState<Style[]>([]);
  const [subStyles, setSubStyles] = useState<Style[]>([]);
  const [beers, setBeers] = useState<readonly Beer[]>([]);
  const [beersLoading, setBeersLoading] = useState(LoadingState.TO_START);
  const [breweries, setBreweries] = useState<Brewery[]>([]);
  const [breweriesLoading, setBreweriesLoading] = useState(
    LoadingState.TO_START,
  );
  const [loadingState, setLoadingState] = useState(LoadingState.TO_START);

  const {
    setValue,
    getValues,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm<ReviewRatingTastingInfo>({
    defaultValues: tastingInfoValues,
    resolver: yupResolver(schemaRatingTastinginfo),
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoadingState(LoadingState.LOADING);
      // styles
      const styles = await getStyles();
      setStyles(styles);

      // colors
      const colors = await getColors();
      setColors(colors);

      // countries
      const countries = await getCountries();
      setCountries(countries);

      setLoadingState(LoadingState.SUCCESS);

      if (getValues('styleId') !== null && styles.length > 0) {
        setSubStyles(
          styles.filter((style) => style.parent?.id === getValues('styleId')),
        );
      }
    };

    fetchData()
      // make sure to catch any error
      .catch((err) => {
        console.error;
        setLoadingState(LoadingState.ERROR);
      });
  }, []);

  const onSubmit = async () => {
    const isFormValid = await trigger();
    if (isFormValid) {
      next();
    }
  };

  const getCurrentStyleById = (id: number | null) => {
    if (styles.length === 0 || id === null) {
      return null;
    }
    const style = styles.find((style) => style.id === id);

    return style ? style : null;
  };

  const getCurrentSubStyleById = (id: number | null) => {
    if (styles.length === 0 || id === null) {
      return null;
    }
    const subStyle = styles.find((style) => style.id === id);
    return subStyle ? subStyle : null;
  };

  const handleStyleChange = (
    value: Style | null,
    reason: AutocompleteChangeReason,
  ) => {
    clearErrors('styleId');
    if (reason === 'clear') {
      setTastingInfoValues({
        ...tastingInfoValues,
        styleId: null,
        subStyleId: null,
      });
      setValue('styleId', null);
      setValue('subStyleId', null);
      return;
    }
    if (value === null || value === undefined) return;
    setValue('subStyleId', null);
    setTastingInfoValues({
      ...tastingInfoValues,
      styleId: value.id,
      subStyleId: null,
    });
    setValue('styleId', value.id);
    setSubStyles(styles.filter((style) => style.parent?.id === value.id));
  };

  const handleSubStyleChange = (
    value: Style | null,
    reason: AutocompleteChangeReason,
  ) => {
    clearErrors('subStyleId');
    if (reason === 'clear') {
      setValue('subStyleId', null);

      setTastingInfoValues({ ...tastingInfoValues, subStyleId: null });
      return;
    }
    if (value === null || value === undefined) return;
    setTastingInfoValues({ ...tastingInfoValues, subStyleId: value.id });
    setValue('subStyleId', value.id);
  };

  const handleColorChange = (color: Color) => {
    clearErrors('color');
    if (color.id === tastingInfoValues.color) {
      setTastingInfoValues({ ...tastingInfoValues, color: null });
    } else {
      setTastingInfoValues({ ...tastingInfoValues, color: color.id });
    }
  };

  const handleCountryNameChange = (
    country: Country | null,
    reason: AutocompleteChangeReason,
  ) => {
    if (reason === 'clear') {
      setTastingInfoValues({
        ...tastingInfoValues,
        countryName: '',
        countryId: null,
      });
      setValue('countryName', '');
      setValue('countryId', null);
      return;
    }

    if (country === null) {
      return;
    }
    setTastingInfoValues({
      ...tastingInfoValues,
      countryName: country.name,
      countryId: country.id,
    });
    setValue('countryName', country.name);
    setValue('countryId', country.id);
  };

  const handleIbuChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setTastingInfoValues({
      ...tastingInfoValues,
      ibu: parseInt(event.target.value),
    });
    setValue('ibu', parseInt(event.target.value));
  };

  const handleAbvChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setTastingInfoValues({
      ...tastingInfoValues,
      abv: parseFloat(event.target.value),
    });
    setValue('abv', parseFloat(event.target.value));
  };

  const searchBeerAutocomplete = (value: string) => {
    setBeersLoading(LoadingState.LOADING);

    if (beersLoading === LoadingState.LOADING) {
      return undefined;
    }

    (async () => {
      const beerResult = await searchBeer(value, 3, 1);
      setBeers(beerResult);
    })();

    setBeersLoading(LoadingState.SUCCESS);
  };

  const searchBreweryAutocomplete = (value: string) => {
    setBreweriesLoading(LoadingState.LOADING);

    if (breweriesLoading === LoadingState.LOADING) {
      return undefined;
    }

    (async () => {
      const breweriesResult = await searchBrewery(value, 3, 1);
      setBreweries(breweriesResult);
    })();

    setBreweriesLoading(LoadingState.SUCCESS);
  };

  function handleSubmitBeerAuto(
    event: React.SyntheticEvent<Element, Event>,
    value: string | Beer | null,
  ) {
    const beerName = value as string;
    setTastingInfoValues({ ...tastingInfoValues, beerName });
    setValue('beerName', beerName);
    if (value === '') {
      setBeers([]);
    } else {
      searchBeerAutocomplete(beerName);
    }
  }

  function handleSubmitBreweryAuto(
    event: React.SyntheticEvent<Element, Event>,
    value: string | Brewery | null,
  ) {
    const breweryName = value as string;
    setTastingInfoValues({ ...tastingInfoValues, breweryName });
    setValue('breweryName', breweryName);
    if (value === '') {
      setBreweries([]);
    } else {
      searchBreweryAutocomplete(breweryName);
    }
  }

  const getCurrentCountryByName = (name: string) => {
    if (countries.length === 0) {
      return null;
    }
    const country = countries.find((country) => country.name === name);
    return country ? country : null;
  };

  const fillBeerField = (beer: Beer) => {
    setTastingInfoValues({
      ...tastingInfoValues,
      beerName: beer.name,
      beerId: beer.id,
      abv: beer.abv,
      ibu: beer.ibu,
      breweryName: beer.brewery.name,
      breweryId: beer.brewery.id,
      countryName: beer.brewery.country.name,
      countryId: beer.brewery.country.id,
      color: beer.color ? beer.color.id - 1 : null,
      styleId: beer.style.parent ? beer.style.parent.id : beer.style.id,
      subStyleId: beer.style.parent ? beer.style.id : null,
    });
    setCurrentStyle(beer.style.parent ? beer.style.parent : beer.style);
    setCurrentSubStyle(beer.style.parent ? beer.style : null);
    setValue('beerName', beer.name);
    setValue('abv', beer.abv);
    setValue('ibu', beer.ibu);
    setValue('breweryName', beer.brewery.name);
    setValue('countryName', beer.brewery.country.name);
    setValue('countryId', beer.brewery.country.id);
    setValue(
      'styleId',
      beer.style.parent ? beer.style.parent.id : beer.style.id,
    );
    setValue('subStyleId', beer.style.parent ? beer.style.id : null);
    setValue('color', beer.color ? beer.color.id - 1 : null);

    if (beer.style.parent !== null) {
      setSubStyles(
        styles.filter((style) => style.parent?.id === beer.style.parent?.id),
      );
    } else {
      setSubStyles([]);
    }
    // TODO: country is a pain in the ass
  };

  const createEmptyBeer = (name: string) => {
    return {
      name,
      id: 0,
      brewery: createEmptyBrewery(''),
      style: { id: 0, name: '', parent: null },
      abv: 0,
      ibu: null,
      color: null,
    };
  };

  const createEmptyBrewery = (name: string) => {
    return {
      name,
      id: 0,
      country: createEmptyCounty(''),
    };
  };

  const createEmptyCounty = (name: string) => {
    return {
      id: 0,
      name: '',
      code: '',
      flag: '',
      phone: '',
    };
  };

  const clearCurrentFields = () => {
    setCurrentStyle(null);
    setCurrentSubStyle(null);
    setValue('countryName', '');
    setValue('countryId', null);
    setSubStyles([]);
    setBeers([]);
  };

  let rootStyles: Style[] = [];

  if (loadingState === LoadingState.SUCCESS) {
    rootStyles = styles.filter((style) => style.parent === null);
  }

  if (loadingState !== LoadingState.SUCCESS) {
    return (
      <Box marginY={2} width="90%">
        <LinearProgress />
      </Box>
    );
  }

  return (
    <form>
      <Stack
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={2}
      >
        <Grid container spacing={2} width="90%" marginY={2}>
          <Grid item xs={12} md={8}>
            {isPreFilled ? (
              <TextField
                value={tastingInfoValues.beerName}
                disabled={isPreFilled}
                label="Beer Name"
                error={!!errors.beerName}
                helperText={
                  errors.beerName ? errors.beerName.message : undefined
                }
                fullWidth
                variant="standard"
              />
            ) : (
              <Autocomplete
                freeSolo
                value={createEmptyBeer(tastingInfoValues.beerName)}
                id="beerSearch"
                filterOptions={(x) => x}
                getOptionLabel={(option) => option.name}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    style={{
                      display: 'grid',
                      gridTemplateRows: 'auto auto',
                      columnGap: '5px',
                    }}
                    {...props}
                  >
                    <p>{option.name}</p>
                    <Typography variant="caption">
                      {option.brewery.name}
                    </Typography>
                  </Box>
                )}
                options={beers}
                loading={beersLoading === LoadingState.LOADING}
                onInputChange={handleSubmitBeerAuto}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    setBeers([]);
                  }
                }}
                onChange={(event, value) => {
                  if (value === null) {
                    setBeers([]);
                  } else if (typeof value === 'object') {
                    fillBeerField(value);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Beer Name"
                    error={!!errors.beerName}
                    helperText={
                      errors.beerName ? errors.beerName.message : undefined
                    }
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={6} md={2} className="with-helper">
            <TextField
              value={tastingInfoValues.abv ? tastingInfoValues.abv : ''}
              disabled={isPreFilled}
              autoComplete="off"
              label="ABV"
              type="number"
              inputProps={{ min: '0', max: '100', step: '0.1' }}
              error={!!errors.abv}
              helperText={errors.abv ? errors.abv.message : undefined}
              fullWidth
              onChange={handleAbvChange}
              onKeyDown={(evt) =>
                ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
              }
              variant="standard"
            />
            <Tooltip
              enterTouchDelay={0}
              title={
                <p>
                  ABV stands for Alcohol By Volume.
                  <br />
                  You can find it on any alcoholic product. It's usually written
                  with a percent sign on its right (for example: 6% means that
                  6% of your drink will be pure alcohol).
                </p>
              }
              className="helper"
            >
              <HelpOutlineIcon fontSize="small" />
            </Tooltip>
          </Grid>
          <Grid item xs={6} md={2} className="with-helper">
            <TextField
              value={tastingInfoValues.ibu ? tastingInfoValues.ibu : ''}
              disabled={isPreFilled}
              autoComplete="off"
              label="IBU"
              type="number"
              inputProps={{ min: '0', step: '1' }}
              error={!!errors.ibu}
              helperText={errors.ibu ? errors.ibu.message : undefined}
              fullWidth
              onChange={handleIbuChange}
              onKeyDown={(evt) =>
                ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
              }
              variant="standard"
            />
            <Tooltip
              enterTouchDelay={0}
              title={
                <p>
                  The International Bitterness Units (IBU) is a scale created to
                  gauge the level of bitterness of a beer. It's an information
                  that most brewers provide on their bottles / cans.
                  <br />
                  For reference, a classic lager usually has an IBU of less than
                  10, an IPA one of around 50 and double or triple IPAs can even
                  reach 100!
                  <br />
                  If you can't find this information, just leave this field
                  empty!
                </p>
              }
              className="helper"
            >
              <HelpOutlineIcon fontSize="small" />
            </Tooltip>
          </Grid>
          <Grid item xs={12} md={8}>
            {isPreFilled ? (
              <TextField
                value={tastingInfoValues.breweryName}
                disabled={isPreFilled}
                label="Brewery Name"
                error={!!errors.breweryName}
                helperText={
                  errors.breweryName ? errors.breweryName.message : undefined
                }
                fullWidth
                variant="standard"
              />
            ) : (
              <Autocomplete
                freeSolo
                value={createEmptyBrewery(tastingInfoValues.breweryName)}
                id="brewerySearch"
                filterOptions={(x) => x}
                getOptionLabel={(option) => option.name}
                options={breweries}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    style={{
                      display: 'grid',
                      gridTemplateRows: 'auto auto',
                      columnGap: '5px',
                    }}
                    {...props}
                  >
                    <p>{option.name}</p>
                    <Box display="inline-block">
                      <img
                        style={{ marginRight: '5px' }}
                        loading="lazy"
                        width="15px"
                        src={`https://flagcdn.com/w40/${option.country.flag.toLowerCase()}.png`}
                        srcSet={`https://flagcdn.com/w80/${option.country.flag.toLowerCase()}.png 2x`}
                        alt=""
                      />
                      <Typography variant="caption">
                        {option.country.name}
                      </Typography>
                    </Box>
                  </Box>
                )}
                loading={breweriesLoading === LoadingState.LOADING}
                onInputChange={handleSubmitBreweryAuto}
                onKeyDown={(e) => {
                  if (e.code === 'Enter') {
                    setBreweries([]);
                  }
                }}
                onChange={(event, value) => {
                  if (value === null) {
                    setBreweries([]);
                  } else if (typeof value === 'object') {
                    setTastingInfoValues({
                      ...tastingInfoValues,
                      breweryName: value.name,
                      countryName: value.country.name,
                      countryId: value.country.id,
                    });
                    setValue('breweryName', value.name);
                    setValue('countryName', value.country.name);
                    setValue('countryId', value.country.id);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Brewery Name"
                    error={!!errors.breweryName}
                    helperText={
                      errors.breweryName
                        ? errors.breweryName.message
                        : undefined
                    }
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            {isPreFilled ? (
              <TextField
                value={tastingInfoValues.countryName}
                disabled={isPreFilled}
                label="Country Name"
                error={!!errors.countryName}
                helperText={
                  errors.countryName ? errors.countryName.message : undefined
                }
                fullWidth
                variant="standard"
              />
            ) : (
              <Autocomplete
                id="AutoCSearch"
                value={getCurrentCountryByName(getValues('countryName'))}
                options={countries}
                getOptionLabel={(option) => option.name}
                onChange={(event, value, reason) =>
                  handleCountryNameChange(value, reason)
                }
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    style={{
                      display: 'grid',
                      gridTemplateColumns: '40px auto',
                      columnGap: '5px',
                      paddingLeft: '5px',
                    }}
                    {...props}
                  >
                    <img
                      style={{ justifySelf: 'center' }}
                      loading="lazy"
                      height="15"
                      src={`https://flagcdn.com/h40/${option.flag.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/h80/${option.flag.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    <p>{option.name}</p>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    disabled={isPreFilled}
                    fullWidth
                    label="Select a country"
                    variant="standard"
                    error={!!errors.countryName}
                    helperText={
                      errors.countryName
                        ? errors.countryName.message
                        : undefined
                    }
                  />
                )}
              />
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            {isPreFilled ? (
              <TextField
                value={getCurrentStyleById(getValues('styleId'))?.name}
                disabled={isPreFilled}
                label="style"
                error={!!errors.styleId}
                helperText={errors.styleId ? errors.styleId.message : undefined}
                fullWidth
                variant="standard"
              />
            ) : (
              <Autocomplete
                value={getCurrentStyleById(getValues('styleId'))}
                disabled={isPreFilled}
                onChange={(event, value, reason) =>
                  handleStyleChange(value, reason)
                }
                id="styleSelect"
                options={rootStyles}
                getOptionLabel={(param) => param.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.styleId}
                    helperText={
                      errors.styleId ? errors.styleId.message : undefined
                    }
                    fullWidth
                    variant="standard"
                    label="Style"
                  />
                )}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {isPreFilled ? (
              <TextField
                value={getCurrentSubStyleById(getValues('subStyleId'))?.name}
                disabled={isPreFilled}
                label="subStyle"
                error={!!errors.subStyleId}
                helperText={
                  errors.subStyleId ? errors.subStyleId.message : undefined
                }
                fullWidth
                variant="standard"
              />
            ) : (
              <Autocomplete
                onChange={(event, value, reason) =>
                  handleSubStyleChange(value, reason)
                }
                id="subStyleSelect"
                value={getCurrentSubStyleById(getValues('subStyleId'))}
                disabled={subStyles.length === 0 || isPreFilled}
                options={subStyles}
                getOptionLabel={(param) => param.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={!!errors.subStyleId}
                    helperText={
                      errors.subStyleId ? errors.subStyleId.message : undefined
                    }
                    fullWidth
                    variant="standard"
                    label={'Substyles (optional)'}
                  />
                )}
              />
            )}
          </Grid>
          <Grid item container md={12} justifyContent="center">
            <div
              id={
                !!errors.color && tastingInfoValues.color === null
                  ? 'form-color-error'
                  : 'form-color-section'
              }
            >
              {' '}
              Color{' '}
            </div>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                align: 'center',
                justify: 'center',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignContent: 'center',
              }}
              marginTop={1}
            >
              {loadingState === LoadingState.SUCCESS &&
                colors.map((color, i) => {
                  return tastingInfoValues.color === color.id ? (
                    <Box
                      id="form-color-selected"
                      key={color.id}
                      className={isPreFilled ? 'form-color-disabled' : ''}
                      style={{
                        backgroundColor: '#' + color.color,
                      }}
                      onClick={
                        isPreFilled
                          ? () => undefined
                          : () => handleColorChange(color)
                      }
                    />
                  ) : (
                    <Box
                      id="form-color"
                      key={color.id}
                      className={isPreFilled ? 'form-color-disabled' : ''}
                      style={{
                        backgroundColor: '#' + color.color,
                      }}
                      onClick={
                        isPreFilled ? undefined : () => handleColorChange(color)
                      }
                    />
                  );
                })}
            </Box>
            {!!errors.color && tastingInfoValues.color === null && (
              <div id="form-color-error"> {errors.color.message} </div>
            )}
          </Grid>
        </Grid>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            pt: 2,
          }}
        >
          <Button
            color="inherit"
            onClick={() => {
              resetTastingInfo();
              clearCurrentFields();
            }}
            variant="outlined"
            sx={{ mr: 1 }}
          >
            Reset
          </Button>

          <Button onClick={onSubmit} variant="contained">
            Next
          </Button>
        </Box>
      </Stack>
    </form>
  );
};

export default TastinInfo;
