import * as React from 'react';
import { getBrewery } from '../Services/api';
import { Brewery } from '../Types/brewery';
import { LoadingState } from '../../../Tech/Types/loadingStates';
import { Box } from '@mui/material';
import './BreweryHeader.css';

interface props {
  breweryId: number;
  stateCallback: (state: LoadingState) => void;
}

const BreweryHeader = ({ breweryId, stateCallback }: props) => {
  const [brewery, setBrewery] = React.useState<Brewery | undefined>();

  React.useEffect(() => {
    stateCallback(LoadingState.LOADING);
    try {
      getBrewery(breweryId).then((data) => setBrewery(data));
      stateCallback(LoadingState.SUCCESS);
    } catch {
      stateCallback(LoadingState.ERROR);
    }
  }, [breweryId]);

  if (brewery === undefined) return null;

  document.title = `Zythogora | ${brewery.name}`;

  return (
    <Box id="brewery-header">
      <h1 id="brewery-name">{brewery.name}</h1>
      <h2 id="brewery-country">
        <img
          src={`https://flagcdn.com/h40/${brewery.country.flag.toLowerCase()}.png`}
          srcSet={`https://flagcdn.com/h80/${brewery.country.flag.toLowerCase()}.png 2x`}
          alt=""
        />
        {brewery.country.name}
      </h2>
    </Box>
  );
};

export default BreweryHeader;
