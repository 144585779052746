import axios from 'axios';
import { API } from '../../../Tech/config/api';
import { Brewery, IBrewery } from '../Types/brewery';
import { Beer } from '../../Beer/Types/beer';

export const getBrewery = async (breweryId: number): Promise<Brewery> => {
  const response = await API.get<Brewery>('breweries/' + breweryId);
  if (response.status !== 200) {
    throw new Error("something wen't wrong");
  }
  return response.data;
};

export const getBreweryBeers = async (breweryId: number): Promise<Beer[]> => {
  const response = await API.get<Beer[]>('breweries/' + breweryId + '/beers');
  if (response.status !== 200) {
    throw new Error("something wen't wrong");
  }
  return response.data;
};

export const postBreweryBeers = async (data: IBrewery): Promise<Brewery> => {
  const response = await API.post<Brewery>('breweries/', data);
  if (response.status !== 200 && response.status !== 409) {
    throw new Error("something wen't wrong");
  }
  return response.data;
};

export const searchBrewery = async (
  input: string,
  count: number,
  page: number,
): Promise<Brewery[]> => {
  const response = await API.get<Brewery[]>(
    `breweries/search/${input}?count=${count}&page=${page}`,
  );
  if (response.status !== 200) {
    throw new Error("something wen't wrong");
  }
  return response.data;
};
